import { Currency, formatAmount } from '@epilot/pricing'
import { scroller } from 'react-scroll'

import { ENV_CONFIG } from 'EnvConfig'
import { i18n } from 'i18n/config'

import { EntityItem } from '../api/ApiTypes'
import { EntityAction } from '../context/PortalConfigContext'

import { Locale } from './LanguageUtils'

export enum RequestStatus {
  Active = 'active',
  Draft = 'draft',
  Quote = 'quote',
  Placed = 'placed',
  Cancelled = 'cancelled',
  Complete = 'complete',
  OpenForAcceptance = 'open_for_acceptance'
}

const getQueryParams = (query: string): Record<string, string> => {
  const params = new URLSearchParams(query)

  const queryParams: Record<string, string> = {}

  params.forEach((value, key) => {
    queryParams[key] = value
  })

  return queryParams
}

const getLocaleDateString = (language: Locale) => {
  switch (language) {
    case Locale.EN:
      return `en-GB`

    case Locale.DE:
      return `de-DE`

    default:
      return `en-GB`
  }
}

export const isEntityHydrated = (entity: EntityItem): boolean => {
  // look up for any relation in the entity
  for (const attribute in entity) {
    if (
      typeof entity[attribute] === 'object' &&
      entity[attribute] !== null &&
      ('$relation_ref' in entity[attribute] || '$relation' in entity[attribute])
    )
      return false
  }

  return true
}

const getEcpDomain = (): string => {
  const pathWithoutQueryData = window.location.href.split('?')[0]

  return window.location.href.includes('localhost')
    ? ENV_CONFIG.DEPLOYED_ECP_URL
    : pathWithoutQueryData
}

const getFiles = (files: any[]): EntityItem[] => {
  if (!files?.length) {
    return []
  }

  const filtered = files.filter((file: EntityItem) => file.filename)

  return filtered
}

const toLocalePrice = (
  price: number,
  currency: Currency | string,
  locale = 'de-DE'
): string => {
  const currencyCode =
    typeof currency === 'string' ? currency : currency ?? 'EUR'

  return formatAmount({
    amount: price ?? 0,
    currency: currencyCode as Currency,
    format: '$0,0.00',
    locale: locale
  })
}

const toLocaleDate = (date: string): string => {
  const dateObject = new Date(date)

  const locale = i18n.language

  const localeDate = dateObject.toLocaleDateString(
    getLocaleDateString(i18n.language as Locale),
    {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    }
  )

  if (locale === 'de-DE') {
    const [day, month, year] = localeDate.split('.')

    return `${day}.${month}.${year}`
  }

  if (locale === 'en') {
    const [day, month, year] = localeDate.split('/')

    return `${day}.${month}.${year}`
  }

  return localeDate
}

const toLocaleDateAndTime = (date: string): string => {
  const dateObject = new Date(date)

  return dateObject.toLocaleTimeString(
    getLocaleDateString(i18n.language as Locale),
    {
      hour12: false,
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit'
    }
  )
}

const getActionsForEntity = (
  actions: EntityAction[],
  entity: string
): EntityAction[] => {
  return actions?.filter((action) => action.slug === entity)
}

const scrollToRow = (id: string, containerId: string): void => {
  scroller.scrollTo(id, {
    duration: 800,
    delay: 0,
    smooth: 'easeInOutQuart',
    containerId,
    offset: -10
  })
}

const wait = (time = 1000) =>
  new Promise((resolve) => setTimeout(resolve, time))

const sanitizeUrl = (url: string): string =>
  url?.match(/^https?:/) ? url : '//' + url

const formatNumber = (
  input: number,
  options?: { thousandsSeparator: boolean }
) => {
  const { thousandsSeparator = true } = options || {}

  // Convert number to string and split into integer and decimal parts
  const [integerPart, decimalPart] = Number(input).toFixed(2).split('.')

  // Add thousand separators to the integer part
  const formattedIntegerPart = thousandsSeparator
    ? integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    : integerPart

  // If there's a decimal part, concatenate it with the appropriate format
  if (decimalPart) {
    return formattedIntegerPart + ',' + decimalPart.padEnd(2, '0')
  } else {
    return formattedIntegerPart + ',00'
  }
}

export default {
  wait,
  getEcpDomain,
  getFiles,
  toLocalePrice,
  toLocaleDate,
  toLocaleDateAndTime,
  getActionsForEntity,
  scrollToRow,
  sanitizeUrl,
  getQueryParams,
  formatNumber
}
