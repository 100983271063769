import { Components } from '@epilot/customer-portal-client'
import merge from 'lodash/fp/merge'
import set from 'lodash/fp/set'
import {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useState
} from 'react'
import { useQuery, useQueryClient } from 'react-query'

import { FeatureFlag, FEATURE_FLAGS } from 'utils/FeatureFlagUtils'

import { PortalOrigin } from '../api/ApiTypes'
import CustomerApiClient, { PORTAL_TYPES } from '../api/CustomerApiClient'
import { CUSTOMER_QUERY_IDS } from '../api/Queries'
import { LabelLanguages } from '../components/shared/editable/EditableButton'
import { useQueryParam } from '../hooks/common/useQueryParam'
import { QUERY_PARAM } from '../hooks/common/useQueryParams'
import AuthUtils from '../utils/AuthUtils'
import {
  DEFAULT_CONFIG_HTML,
  getDefaultAuthLinkHtml
} from '../utils/ConfigEditorUtils'
import { EntityType } from '../utils/EntityUtils'
import { getCurrentLanguage, setLanguage } from '../utils/LanguageUtils'
import PermissionsUtils from '../utils/PermissionsUtils'
import { isConfigMode } from '../utils/StyleUtils'

interface HtmlTemplate {
  termsConditions: string
  privacySettings: string
  alreadyHaveAccount: string
  notRegisteredYet: string
  loginFooterFirstSection: string
  loginFooterSecondSection: string
  registerFooterFirstSection: string
  registerFooterSecondSection: string
}

export enum ExternalLinkType {
  Link = 'link',
  Journey = 'journey'
}

export interface ExternalLink {
  id: string
  link: string
  type: ExternalLinkType
  label: {
    en: string
    de: string
  }
  rules?: Rule[]
}

interface ButtonOverride {
  [key: string]: {
    label: LabelLanguages
    link?: string
  }
}

export enum ImageKey {
  OrderLeftTeaser = 'orderLeftTeaser',
  OrderRightTeaser = 'orderRightTeaser',
  LoginHeaderLogo = 'loginHeaderLogo',
  RegisterHeaderLogo = 'registerHeaderLogo',
  LoginFooterLogo = 'loginFooterLogo',
  RegisterFooterLogo = 'registerFooterLogo',
  LoginBanner = 'loginBanner',
  RegisterBanner = 'registerBanner'
}

export declare type HTtmlContext =
  | 'termsConditions'
  | 'privacySettings'
  | 'alreadyHaveAccount'
  | 'notRegisteredYet'
  | 'loginFooterFirstSection'
  | 'loginFooterSecondSection'
  | 'registerFooterFirstSection'
  | 'registerFooterSecondSection'

export type AvailableLanguage = 'german' | 'english'

export type SelfRegistrationSetting =
  | 'ALLOW_WITH_CONTACT_CREATION'
  | 'ALLOW_WITHOUT_CONTACT_CREATION'
  | 'DENY'

export type LeftSideBullet = {
  title: string
  description: string
}

interface AuthPageConfig {
  showLeftSideLayout?: boolean
  showLeftSideImage?: boolean
  showLeftSideTitle?: boolean
  showLeftSideBullets?: boolean
  leftSideBullets?: {
    en: LeftSideBullet[]
    de: LeftSideBullet[]
  }
  header?: {
    show: boolean
    showLogo: boolean
  }
  footer?: {
    show: boolean
    showLogo: boolean
    showFirstSection: boolean
    showSecondSection: boolean
    links: {
      label: LabelLanguages
      link: string
    }[]
  }
}

interface Config {
  additionNavigations?: any
  selectedCategories: [
    {
      id: number
      translationKey: string
    }
  ]
  translationOverrides: any
  html: {
    en: HtmlTemplate
    de: HtmlTemplate
  }
  externalLinks?: ExternalLink[]
  teasers: {
    left: { show: boolean; showButton: boolean }
    right: { show: boolean; showButton: boolean }
  }
  buttonOverrides: ButtonOverride[]
  available_languages?: AvailableLanguage[]
  login: AuthPageConfig
  register: AuthPageConfig
  allowed_domain?: string
}

export interface Grant {
  action: string
  resource: string
}

export interface Rule {
  entity?: string
  attribute?: string
  attribute_value?: string
}

export interface EntityAction {
  journey_id?: string
  action_label?: {
    en?: string
    de?: string
  }
  slug?: string
  rules?: Rule[]
}

export interface PortalConfig {
  organization_id: string
  org_name: string
  name: string
  design_id: string
  enabled?: boolean
  config: Config
  self_registration_setting: SelfRegistrationSetting
  cognito_details: {
    cognito_user_pool_id: string
    cognito_user_pool_arn: string
    cognito_user_pool_client_id: string
    cognito_identity_pool_id: string
    password_policy: {
      minimum_length: number
      require_lowercase: boolean
      require_numbers: boolean
      require_symbols: boolean
      require_uppercase: boolean
    }
  }
  approval_state_attributes: {
    [key: string]: string[]
  }
  contact_identifiers: string[]
  registration_identifiers: Components.Schemas.RegistrationIdentifier[]
  contract_identifiers?: Components.Schemas.RegistrationIdentifier[]
  images?: {
    orderRightTeaser: string | Blob
    orderLeftTeaser: string | Blob
    loginBanner?: string | Blob
    loginHeaderLogo?: string | Blob
    loginFooterLogo?: string | Blob
    registerBanner?: string | Blob
    registerHeaderLogo?: string | Blob
    registerFooterLogo?: string | Blob
  }
  grants: Grant[]
  feature_settings?: {
    [name: string]: boolean
  }
  advanced_mfa?: {
    enabled: boolean
  }
  feature_flags?: Record<(typeof FEATURE_FLAGS)[FeatureFlag], boolean>
  entity_actions?: EntityAction[]
  origin: string
  allowed_file_extensions: Record<string, string[]>
  org_settings: Components.Schemas.PortalConfig['org_settings']
  entity_edit_rules?: EntityEditRule[]
  domain: string
  accessToken: string
}

export interface EntityEditRule {
  slug: string
  attribute: string
  rule_type: string
  cadence_period_type?: string
  changes_allowed?: number
  cadence_period?: number
  allowed_decrement?: string
  allowed_increment?: string
  number_of_days_before_restriction?: number
  grace_period?: number
}

export interface PortalConfigContextData {
  initialized: boolean
  portalConfig: PortalConfig
  originalConfig: PortalConfig
}

interface TeaserConfig {
  show?: boolean
  showButton?: boolean
}

export enum PermissionsAction {
  ENTITY_VIEW = 'entity:view',
  ENTITY_CREATE = 'entity:create',
  ENTITY_EDIT = 'entity:edit',
  ENTITY_DELETE = 'entity:delete',
  ATTRIBUTE_VIEW = 'entity:attribute:view',
  ATTRIBUTE_EDIT = 'entity:attribute:edit',
  ATTRIBUTE_CREATE = 'entity:attribute:create',
  ATTRIBUTE_DELETE = 'entity:attribute:delete',
  METER_READING_VIEW = 'meter_reading:view',
  METER_READING_CREATE = 'meter_reading:create'
}

export interface PortalConfigContextState {
  data: PortalConfigContextData
  actions: {
    updateConfigHtmlByPath: (path: HTtmlContext, value: string) => void
    updateImageOverrides: (path: ImageKey, src?: File) => void
    updateTeasers: (teaser: 'left' | 'right', config: TeaserConfig) => void
    updateButtonOverrides: (button: ButtonOverride) => void
    updateDataAtPath: (path: string, value: any) => void
    isPermitted: (action: PermissionsAction, resource: string) => boolean
    isEntityPermitted: (entity: EntityType) => boolean
    isOneAttributePermittedInGroup: (schema: EntityType) => boolean
    resetImage: (imageKey: string) => void
  }
}

const defaultState: PortalConfigContextState = {
  data: {
    initialized: false,
    portalConfig: {
      organization_id: null,
      org_name: null,
      name: null,
      design_id: null,
      self_registration_setting: 'DENY',
      contact_identifiers: [],
      registration_identifiers: [],
      contract_identifiers: [],
      approval_state_attributes: {},
      config: {
        selectedCategories: [
          {
            id: null,
            translationKey: null
          }
        ],
        translationOverrides: null,
        html: {
          en: {
            termsConditions: '',
            privacySettings: '',
            alreadyHaveAccount: '',
            notRegisteredYet: '',
            loginFooterFirstSection: '',
            loginFooterSecondSection: '',
            registerFooterFirstSection: '',
            registerFooterSecondSection: ''
          },
          de: {
            termsConditions: '',
            privacySettings: '',
            alreadyHaveAccount: '',
            notRegisteredYet: '',
            loginFooterFirstSection: '',
            loginFooterSecondSection: '',
            registerFooterFirstSection: '',
            registerFooterSecondSection: ''
          }
        },
        teasers: {
          left: { show: false, showButton: true },
          right: { show: false, showButton: true }
        },
        buttonOverrides: [],
        login: {
          showLeftSideLayout: true,
          showLeftSideImage: true,
          showLeftSideTitle: true,
          showLeftSideBullets: true,
          leftSideBullets: {
            en: [
              {
                title: 'welcome_banner.login.information.one.title',
                description: 'welcome_banner.login.information.one.description'
              },
              {
                title: 'welcome_banner.login.information.two.title',
                description: 'welcome_banner.login.information.two.description'
              },
              {
                title: 'welcome_banner.login.information.three.title',
                description:
                  'welcome_banner.login.information.three.description'
              }
            ],
            de: [
              {
                title: 'welcome_banner.login.information.one.title',
                description: 'welcome_banner.login.information.one.description'
              },
              {
                title: 'welcome_banner.login.information.two.title',
                description: 'welcome_banner.login.information.two.description'
              },
              {
                title: 'welcome_banner.login.information.three.title',
                description:
                  'welcome_banner.login.information.three.description'
              }
            ]
          },
          header: {
            show: false,
            showLogo: false
          },
          footer: {
            show: false,
            showLogo: false,
            showFirstSection: false,
            showSecondSection: false,
            links: []
          }
        },
        register: {
          showLeftSideLayout: true,
          showLeftSideImage: true,
          showLeftSideTitle: true,
          showLeftSideBullets: true,
          leftSideBullets: {
            en: [
              {
                title: 'welcome_banner.register.information.one.title',
                description:
                  'welcome_banner.register.information.one.description'
              },
              {
                title: 'welcome_banner.register.information.two.title',
                description:
                  'welcome_banner.register.information.two.description'
              },
              {
                title: 'welcome_banner.register.information.three.title',
                description:
                  'welcome_banner.register.information.three.description'
              }
            ],
            de: [
              {
                title: 'welcome_banner.register.information.one.title',
                description:
                  'welcome_banner.register.information.one.description'
              },
              {
                title: 'welcome_banner.register.information.two.title',
                description:
                  'welcome_banner.register.information.two.description'
              },
              {
                title: 'welcome_banner.register.information.three.title',
                description:
                  'welcome_banner.register.information.three.description'
              }
            ]
          },
          header: {
            show: false,
            showLogo: false
          },
          footer: {
            show: true,
            showLogo: false,
            showFirstSection: false,
            showSecondSection: false,
            links: []
          }
        },
        allowed_domain: ''
      },
      cognito_details: {
        cognito_user_pool_id: null,
        cognito_user_pool_arn: null,
        cognito_user_pool_client_id: null,
        cognito_identity_pool_id: null,
        password_policy: {
          minimum_length: 0,
          require_lowercase: false,
          require_numbers: false,
          require_symbols: false,
          require_uppercase: false
        }
      },
      images: {
        orderRightTeaser: null,
        orderLeftTeaser: null,
        loginBanner: null,
        loginHeaderLogo: null,
        registerBanner: null,
        registerHeaderLogo: null,
        loginFooterLogo: null,
        registerFooterLogo: null
      },
      grants: null,
      origin: '',
      allowed_file_extensions: {},
      org_settings: {},
      domain: '',
      accessToken: ''
    },
    originalConfig: null
  },
  actions: {
    updateConfigHtmlByPath: () => null,
    updateImageOverrides: () => null,
    updateTeasers: () => null,
    updateButtonOverrides: () => null,
    updateDataAtPath: () => null,
    isPermitted: () => false,
    isEntityPermitted: () => false,
    isOneAttributePermittedInGroup: () => false,
    resetImage: () => null
  }
}

const PortalConfigContext =
  createContext<PortalConfigContextState>(defaultState)

export interface PortalConfigProviderProps {
  children: ReactNode
  domain: string
}

const mapApiConfigToUI = (data: PortalConfig): PortalConfig => {
  let mappedData: PortalConfig = { ...data }

  mappedData = merge(mappedData, {
    config: mappedData?.config ? JSON.parse(mappedData.config as any) : null
  })

  const defaultAuthHtml = getDefaultAuthLinkHtml(mappedData.domain)

  mappedData = merge(mappedData, {
    config: {
      html: {
        en: {
          termsConditions:
            mappedData?.config?.html?.en?.termsConditions ||
            DEFAULT_CONFIG_HTML.en.termsConditions,
          privacySettings:
            mappedData.config?.html?.en?.privacySettings ||
            DEFAULT_CONFIG_HTML.en.privacySettings,
          alreadyHaveAccount:
            mappedData.config?.html?.en?.alreadyHaveAccount ||
            defaultAuthHtml.en.alreadyHaveAccount,
          notRegisteredYet:
            mappedData.config?.html?.en?.notRegisteredYet ||
            defaultAuthHtml.en.notRegisteredYet
        },
        de: {
          termsConditions:
            mappedData.config?.html?.de?.termsConditions ||
            DEFAULT_CONFIG_HTML.de.termsConditions,
          privacySettings:
            mappedData.config?.html?.de?.privacySettings ||
            DEFAULT_CONFIG_HTML.de.privacySettings,
          alreadyHaveAccount:
            mappedData.config?.html?.de?.alreadyHaveAccount ||
            defaultAuthHtml.de.alreadyHaveAccount,
          notRegisteredYet:
            mappedData.config?.html?.de?.notRegisteredYet ||
            defaultAuthHtml.de.notRegisteredYet
        }
      }
    }
  })

  return mappedData
}

const PortalConfigProvider = ({
  children,
  domain
}: PortalConfigProviderProps) => {
  const [initialized, setIsInitialized] = useState<boolean>(false)
  const [portalConfigData, setPortalConfigData] = useState<PortalConfig>(
    defaultState.data.portalConfig
  )
  const [originalConfig, setOriginalConfig] = useState<PortalConfig>(
    defaultState.data.originalConfig
  )

  const queryOrgId: string = useQueryParam(QUERY_PARAM.orgId)
  const queryOrigin: string = useQueryParam(QUERY_PARAM.origin)

  const queryClient = useQueryClient()

  const { data: portalConfig } = useQuery(
    CUSTOMER_QUERY_IDS.GET_PORTAL_CONFIG,
    () => {
      if (isConfigMode()) {
        return CustomerApiClient.getPublicPortalConfig({
          origin:
            (queryOrigin as PortalOrigin) ||
            (PORTAL_TYPES.endCustomerPortal as PortalOrigin),
          org_id: queryOrgId
        })
      } else {
        return CustomerApiClient.getPortalConfigByDomain(domain)
      }
    },
    {
      onSuccess: (data: PortalConfig) => {
        if (!data) {
          setIsInitialized(true)

          return
        }

        const portalData: PortalConfig = mapApiConfigToUI(data)

        setLanguage(portalData.config?.available_languages)

        updateConfig(portalData)
        setOriginalConfig(portalData)

        if (!initialized) {
          setIsInitialized(true)
        }
      }
    }
  )

  // Subscribe on PORTAL_SAVED_EVENT
  useEffect(() => {
    const getWidgetsEventHandler = (event: MessageEvent) => {
      if (event.data.type === 'PORTAL_SAVED_EVENT') {
        queryClient.invalidateQueries(CUSTOMER_QUERY_IDS.GET_PORTAL_CONFIG)
      }
    }

    window.addEventListener('message', getWidgetsEventHandler)

    return () => {
      window.removeEventListener('message', getWidgetsEventHandler)
    }
  }, [queryClient])

  const updateConfigHtmlByPath = useCallback(
    (path: HTtmlContext, value: string): void => {
      const updatedConfig: PortalConfig = merge(portalConfigData, {
        config: { html: { [getCurrentLanguage()]: { [path]: value } } }
      })

      setPortalConfigData(updatedConfig)

      window.parent.postMessage(
        {
          type: 'paragraph_override',
          data: { [getCurrentLanguage()]: { [path]: value } }
        },
        '*'
      )
    },
    [portalConfigData]
  )

  const updateImageOverrides = useCallback(
    (key: ImageKey, img = '') => {
      const updatedConfig: PortalConfig = merge(portalConfigData, {
        images: { [key]: img }
      })

      setPortalConfigData(updatedConfig)

      window.parent.postMessage(
        {
          type: 'image_override',
          data: { [key]: img }
        },
        '*'
      )
    },
    [portalConfigData]
  )

  const resetImage = (imageKey: string): void => {
    const previousImage: string = originalConfig.images?.[imageKey]

    const updatedConfig: PortalConfig = merge(portalConfigData, {
      images: { [imageKey]: previousImage }
    })

    setPortalConfigData(updatedConfig)

    window.parent.postMessage(
      {
        type: 'image_override',
        data: { [imageKey]: previousImage }
      },
      '*'
    )
  }

  const updateDataAtPath = useCallback((path: string, value: any) => {
    setPortalConfigData((oldPortalData: PortalConfig) => {
      const updatedConfig = set(path, value)(oldPortalData)

      window.parent.postMessage(
        {
          type: 'config_updated_at_path',
          data: { path, value }
        },
        '*'
      )

      return updatedConfig
    })
  }, [])

  const updateTeasers = useCallback(
    (teaser: 'left' | 'right', config: TeaserConfig): void => {
      const removeLeftTeaserOverriddenData = (config: PortalConfig) => {
        delete config.config.buttonOverrides?.['teaser_left_call_to_action']
        delete config.images?.orderLeftTeaser

        return config
      }

      const removeRightTeaserOverriddenData = (config: PortalConfig) => {
        delete config.config.buttonOverrides?.['teaser_right_call_to_action']
        delete config.images?.orderRightTeaser

        return config
      }

      let updatedConfig: PortalConfig = merge(portalConfigData, {
        config: { teasers: { [teaser]: config } }
      })

      if (teaser === 'left' && !updatedConfig.config.teasers['left']?.show) {
        updatedConfig = removeLeftTeaserOverriddenData(updatedConfig)
      }

      if (teaser === 'right' && !updatedConfig.config.teasers['right']?.show) {
        updatedConfig = removeRightTeaserOverriddenData(updatedConfig)
      }

      setPortalConfigData(updatedConfig)

      window.parent.postMessage(
        {
          type: 'update_teasers',
          data: { [teaser]: config }
        },
        '*'
      )
    },
    [portalConfigData]
  )

  const updateButtonOverrides = useCallback(
    (button: ButtonOverride): void => {
      const updatedConfig: PortalConfig = merge(portalConfigData, {
        config: { buttonOverrides: button }
      })

      setPortalConfigData(updatedConfig)

      window.parent.postMessage(
        {
          type: 'button_override',
          data: button
        },
        '*'
      )
    },
    [portalConfigData]
  )

  const isPermitted = useCallback(
    (action: PermissionsAction, resource: string): boolean => {
      return PermissionsUtils.isGrantPermitted(
        portalConfigData.grants,
        action,
        resource
      )
    },
    [portalConfigData.grants]
  )

  const isEntityPermitted = useCallback(
    (slug: EntityType): boolean => {
      return PermissionsUtils.isGrantPermitted(
        portalConfigData.grants || [],
        PermissionsAction.ENTITY_VIEW,
        slug
      )
    },
    [portalConfigData.grants]
  )

  const isOneAttributePermittedInGroup = (schema: EntityType): boolean => {
    const regex = new RegExp(`${schema}:.*:.*`, 'g')

    return !!portalConfig?.grants?.find((grant: Grant) => {
      const hasActionGrant: boolean =
        grant.action === PermissionsAction.ATTRIBUTE_VIEW
      const hasResourceGrant: boolean = regex.test(grant.resource)

      return hasActionGrant && hasResourceGrant
    })
  }

  const updateConfig = useCallback((config: PortalConfig) => {
    setPortalConfigData(config)
  }, [])

  if (portalConfig) {
    AuthUtils.setPortalId(portalConfig.organization_id)
  }

  return (
    <PortalConfigContext.Provider
      value={{
        data: {
          initialized,
          portalConfig: portalConfigData,
          originalConfig
        },
        actions: {
          updateConfigHtmlByPath,
          updateImageOverrides,
          updateTeasers,
          updateButtonOverrides,
          updateDataAtPath,
          isPermitted,
          isEntityPermitted,
          isOneAttributePermittedInGroup,
          resetImage
        }
      }}
    >
      {children}
    </PortalConfigContext.Provider>
  )
}

export { PortalConfigProvider }
export default PortalConfigContext
